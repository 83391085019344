<template>
  <div class="dry-power">
    <!-- <moduleTitle title="农田基本情况"></moduleTitle> -->
    <div class="title">{{ title }}</div>
    <div class="dry-power-content">
      <div
        class="item"
        @click="openModel(item)"
        v-for="(item, index) in data"
        :key="index + 's'"
      >
        <div class="item-title">
          <div class="dot" :class="title | dotStyle"></div>
          <div class="text">{{ item.jobType }}-{{ item.jobObj }}</div>
        </div>

        <div v-if="title == '作业价格'" class="data" :class="title | rStyle">
          {{ item.jobPrice }}
          <span
v-if="item.jobType == '加工作业' || item.jobType == '烘干作业'"
            >元/吨</span
          >
          <span v-else-if="item.jobType == '育秧作业'">元/盘</span>
          <span v-else>元/亩</span>
        </div>
        <div v-else class="data" :class="title | rStyle">
          {{ item.jobPrice }}
          <span>元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    dotStyle (value) {
      switch (value) {
      case '当日新增订单':
        return 'rtopT';
      case '当日完成订单':
        return 'rcenterT';
      case '作业价格':
        return 'rbottomT';
      }
    },
    rStyle (value) {
      switch (value) {
      case '当日新增订单':
        return 'rtop';
      case '当日完成订单':
        return 'rcenter';
      case '作业价格':
        return 'rbottom';
      }
    }
  },
  data () {
    return {};
  },
  methods: {
    openModel (e) {
      let status = '';
      switch (this.title) {
      case '当日新增订单':
        status = 'todayStart';
        break;
      case '当日完成订单':
        status = 'todayFinish';
        break;
      case '作业价格':
        status = 'todayNum';
        break;
      }
      this.$listeners.getTabal(
        {
          pageNum: 1,
          pageSize: 5,
          status: status,
          type: e.job_type
        },
        this.title
      );
      // console.log(e,'eeee')
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  width: 100%;
  height: 100%;
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(
      0deg,
      rgba(130, 245, 255, 0.4),
      rgba(130, 245, 255, 0.1)
    )
    10 10;

  .title {
    width: calc(100% - companyW(50vw));
    padding-left: companyW(50vw);
    height: companyH(40vh);
    line-height: companyH(40vh);
    color: #ffffff;
    font-weight: bold;
    background-image: url('../../../assets/home-page/titleImg.png');
    background-size: 100% 200%;
    background-repeat: no-repeat;
  }

  .dry-power-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 companyW(25vw);
    .item {
      width: 30%;
      .item-title {
        padding-left: companyW(5vw);
        display: flex;
        color: #fff;
        .dot {
          width: companyW(10vw);
          height: companyW(10vw);
          background-color: #fff;
          margin: companyH(10vh) companyW(5vw) 0 0;
        }
        .rtopT {
          background-color: rgba(0, 168, 255, 1);
        }
        .rcenterT {
          background-color: rgba(7, 177, 133, 1);
        }
        .rbottomT {
          background-color: rgba(115, 136, 196, 1);
        }
        height: companyH(30vh);
        line-height: companyH(30vh);
      }
      .data {
        color: black;
        width: 100%;
        height: companyH(50vh);
        line-height: companyH(50vh);
        font-weight: bold;
        text-align: center;
      }
      .rtop {
        color: rgba(0, 168, 255, 1);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../../../assets/home-page/righttop.png');
      }
      .rcenter {
        color: rgba(7, 177, 133, 1);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../../../assets/home-page/rightcenter.png');
      }
      .rbottom {
        color: rgba(115, 136, 196, 1);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../../../assets/home-page/rightbottom.png');
      }
    }
  }
}
</style>
