<template>
  <div class="home">
    <div class="homebj">
      <div class="toubu">现代农事服务中心数字化管理平台</div>
      <div
        class="rightBgc anniu1"
        @click="jumpshouye"
        v-show="this.level.level !== 0"
      >
        <img src="../assets/njfwimgs/shouye.png" class="imgBox" />
        <p>返回</p>
      </div>

      <div
        class="rightBgc anniu3"
        @click="toback"
        v-show="this.level.level !== 0"
      >
        <img src="../assets/njfwimgs/quanping.png" class="imgBox" />
        <p>全屏</p>
      </div>
      <div class="homeBox">
        <div class="homeBoxLeft">
          <div class="leftTop">
            <leftCenter></leftCenter>
          </div>
          <div class="leftCenter">
            <Power :ntjbqk="ntjbqk"></Power>
          </div>
          <div class="leftBottom">
            <Right
              v-bind="$attrs"
              :downQxValue="downQxValue"
              @getListData="getListData"
              :downList1="downList1"
              :downList4="downList4"
              :rightData="rightData"
              v-on="$listeners"
            />
          </div>
        </div>
        <div class="homeBoxCenter">
          <div class="nsfw">
            <div class="shadow"></div>
            <div class="nsfwlogo"></div>
            <div class="nsfw-box">
              <div :class="item.cssClass" v-for="item in nsfwIndex" :key="item.id">
                <img
                  :src="item.modelIcon"
                  @click="godetail(item)"
                />
                <span class="wenben">{{item.modelName}}</span>
              </div>

            </div>
            <!-- <div class="sznj">
            </div> -->
            <!-- <div class="sznt">

            </div> -->
            <!-- <div class="yyzx">

            </div> -->
            <!-- <div class="hgzx">

            </div> -->
            <!-- <div class="jgzx">

            </div> -->
            <!-- <div class="njfw">

            </div> -->
            <!-- <div class="gxtz">
              <img src="../assets/home-page/gxtz.png" @click="navigateTo('')" alt="" />
              <span class="wenben">个性拓展</span>
            </div> -->
          </div>
        </div>
        <div class="homeBoxRight">
          <div class="dyr-service-right">
            <leftBottom v-on="$listeners" :data="listData1[3]"></leftBottom>
          </div>
          <div class="rightCenter">
            <leftBottom
              v-on="$listeners"
              :data="listData[3]"
              @getTabal="getTabal"
            ></leftBottom>
          </div>

          <div class="rightBotton">
            <rightModel
              v-on="$listeners"
              :title="'作业价格'"
              :data="listData1[2]"
            >
            </rightModel>
          </div>
        </div>
      </div>
    </div>
    <MaskBox
      v-on="$listeners"
      @getTabal="getTabal"
      :tableTotal="tableTotal"
      :modelTitle="modelTitle"
      :changeMask="showMask"
      :mainTable="TableTitle"
      showNav="false"
      @getMask="closeMask"
      :NavList="NavList"
      :TanleContent="tableData"
    >
    </MaskBox>
  </div>
</template>
<script>
import revolve from '../components/revolve/index.vue';
import {
  getNtjbqk,
  selectAgriType,
  selectAgriMain,
  getMain,
  getOrderInfo
} from '../api/home/index.js';
import Power from './home/components/power.vue';
import leftCenter from './home/components/leftCenter.vue';
import leftBottom from './home/components/leftBottom.vue';
import rightModel from './home/components/rightModel.vue';
import MaskBox from './home/components/MaskTool1.vue';
import Right from './home/components/right/index.vue';
import {
  fieldBasic,
  getNtmj,
  getQxdp,
  getSqxq,
  getSeedlingsByName,
  getPestMonitoringByName,
  getSeedlingNames,
  getWaterQuality,
  device,
  getAlarmInfo,
  getIdName
} from '../api/numberApi/index';

import { getIndexNJcount } from '@/api/njApi/njzl.js';
import { getAllprice } from '@/api/njApi/njdd.js';
import { getindexData } from '@/api/nfhs/index.js';
export default {
  components: {
    Power,
    leftCenter,
    leftBottom,
    rightModel,
    MaskBox,
    revolve,
    Right
  },
  data () {
    return {
      nsfwIndex:[],
      downList1: [],
      rightData: {
        //气象信息数据
        qxData: {},
        //墒情信息数据
        sqxqData: {},
        //水质信息
        szData: {}
      },
      downQxValue: '',
      //土壤监测点id
      deviceId: '',
      downList4: [],
      level: JSON.parse(sessionStorage.getItem('INFO')),
      NavList: {},
      tableData: [],
      ntjbqk: {},
      //数据条数
      tableTotal: 0,
      listData: [ [], [], [], [] ],
      listData1: [ [], [], [], [] ],
      modelTitle: '',
      //弹窗分页
      pagData: {
        pageNum: 1,
        pageSize: 5,
        jobType: '',
        modelTitle: ''
      },
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          top: '5%',
          left: 'center'
        },
        tooltip: {
          formatter: '{b} {d}%<br />{c}'
        },
        grid: {},
        series: [
          {
            itemStyle: {
              borderWidth: 0
            },
            type: 'pie',
            radius: [ '50%', '80%' ],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: 1048,
                name: 'Search Engine'
              },
              {
                value: 735,
                name: 'Direct'
              },
              {
                value: 580,
                name: 'Email'
              },
              {
                value: 484,
                name: 'Union Ads'
              },
              {
                value: 300,
                name: 'Video Ads'
              }
            ]
          }
        ]
      },
      TableTitle: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'jobObj',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobTractorName',
          label: '操作手',
          width: '80'
        },
        {
          id: 5,
          prop: 'jobNum',
          label: '田块面积',
          width: '100'
        },
        {
          id: 6,
          prop: 'jobPrice',
          label: '作业价格',
          width: '100'
        },
        {
          id: 7,
          prop: 'orderFinishTime',
          label: '作业时间',
          width: '100'
        },
        {
          id: 8,
          prop: 'jobWay',
          label: '作业路径',
          width: '100',
          btn: true
        },
        {
          id: 9,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true
        }
      ],

      //育秧
      TableTitle1: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmerName',
          label: '订单业主',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'seedingVariety',
          label: '育秧品种',
          seedingVariety: true,
          width: '80'
        },
        // { id: 4, prop: 'seedingType', label: '育秧类型', width: '100' },
        {
          id: 5,
          prop: 'seedingPrice',
          label: '育秧价格',
          width: '100'
        },
        {
          id: 6,
          prop: 'seedingNum',
          label: '育秧数量',
          width: '100'
        },
        {
          id: 7,
          prop: 'orderFinishTime',
          label: '要求交苗时间',
          width: '180'
        },
        {
          id: 8,
          address: true,
          label: '交苗地址',
          width: '100'
        }
      ],
      //育秧完成
      TableTitle1A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmerName',
          label: '订单业主',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'seedingVariety',
          label: '育秧品种',
          seedingVariety: true,
          width: '80'
        },
        // { id: 4, prop: 'seedingType', label: '育秧类型', width: '100' },
        {
          id: 5,
          prop: 'seedingPrice',
          label: '育秧价格',
          width: '100'
        },
        {
          id: 6,
          prop: 'seedingNum',
          label: '育秧数量',
          width: '100'
        },
        {
          id: 7,
          prop: 'orderFinishTime',
          label: '完成时间',
          width: '180'
        },
        {
          id: 8,
          address: true,
          label: '交苗地址',
          width: '100'
        }
      ],
      //机插
      TableTitle2: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'pesticidesSource',
          label: '秧苗来源',
          width: '100'
        },
        {
          id: 6,
          prop: 'jobTractorName',
          label: '意向机手',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        },
        {
          id: 10,
          label: '操作',
          width: '100',
          operate: true
        }
      ],
      //机插完成
      TableTitle2A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'pesticidesSource',
          label: '秧苗来源',
          width: '100'
        },
        {
          id: 5,
          prop: 'jobTractorName',
          label: '操作手',
          width: '80'
        },
        {
          id: 6,
          prop: 'jobTractorTel',
          label: '机手电话',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'zyTime',
          label: '作业时间',
          width: '150'
        },
        {
          id: 10,
          btn: true,
          label: '作业路径',
          width: '100'
        },
        {
          id: 11,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true
        }
      ],
      //植保
      TableTitle3: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 5,
          prop: 'pesticidesSource',
          label: '农药来源',
          width: '80'
        },
        {
          id: 6,
          prop: 'jobTractorName',
          label: '意向机手',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        },
        {
          id: 10,
          label: '操作',
          width: '100',
          operate: true
        }
      ],
      //植保完成
      TableTitle3A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 5,
          prop: 'pesticidesSource',
          label: '农药来源',
          width: '80'
        },
        {
          id: 6,
          prop: 'jobTractorName',
          label: '操作手',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobTractorTel',
          label: '机手电话',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 9,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 10,
          prop: 'appoint',
          label: '作业时间',
          width: '150'
        },
        {
          id: 11,
          btn: true,
          label: '作业路径',
          width: '100'
        },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true
        }
      ],
      //加工
      TableTitle4: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 5,
          prop: 'dryingCount',
          label: '加工数量',
          width: '100'
        },
        {
          id: 6,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        }
      ],
      //加工完成
      TableTitle4A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'jobObj',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 5,
          prop: 'dryingCount',
          label: '加工数量',
          width: '100'
        },
        {
          id: 6,
          prop: 'zyTime',
          label: '作业时间',
          width: '150'
        },
        {
          id: 7,
          prop: 'outTower',
          label: '出米数量',
          width: '150'
        }
      ],
      //机收
      TableTitle5: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 6,
          prop: 'jobTractorName',
          label: '意向机手',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        },
        {
          id: 10,
          label: '操作',
          width: '100',
          operate: true
        }
      ],
      //机收完成
      TableTitle5A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 5,
          prop: 'jobTractorName',
          label: '操作手',
          width: '80'
        },
        {
          id: 6,
          prop: 'jobTractorTel',
          label: '机手电话',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'zyTime',
          label: '作业时间',
          width: '150'
        },
        {
          id: 11,
          btn: true,
          label: '作业路径',
          width: '100'
        },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true
        }
      ],
      //烘干
      TableTitle6: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'jobObj',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '烘干数量',
          width: '100'
        },
        {
          id: 9,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        }
      ],
      //烘干完成
      TableTitle6A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '订单业主',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobObj',
          label: '作物类型',
          width: '100'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '烘干数量',
          width: '100'
        },
        {
          id: 9,
          prop: 'zyTime',
          label: '作业时间',
          width: '150'
        },
        {
          id: 10,
          prop: 'outTower',
          label: '出塔数量',
          width: '150'
        }
      ],
      //耕整
      TableTitle7: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 4,
          prop: 'jobTractorName',
          label: '意向机手',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'appoint',
          label: '要求作业时间',
          width: '150'
        },
        {
          id: 10,
          label: '操作',
          width: '100',
          operate: true
        }
      ],
      //耕整完成
      TableTitle7A: [
        {
          id: 1,
          prop: 'serialNum',
          label: '序号',
          width: '60'
        },
        {
          id: 2,
          prop: 'farmer',
          label: '作业对象',
          width: '100'
        },
        {
          id: 3,
          prop: 'farmerTel',
          label: '农户电话',
          width: '100'
        },
        {
          id: 5,
          prop: 'jobTractorName',
          label: '操作手',
          width: '80'
        },
        {
          id: 6,
          prop: 'jobTractorTel',
          label: '机手电话',
          width: '80'
        },
        {
          id: 7,
          prop: 'jobPrice',
          label: '作业价格',
          width: '80'
        },
        {
          id: 8,
          prop: 'jobNum',
          label: '作业面积',
          width: '100'
        },
        {
          id: 9,
          prop: 'zyTime',
          label: '作业时间',
          width: '150'
        },
        {
          id: 11,
          btn: true,
          label: '作业路径',
          width: '100'
        },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true
        }
      ],
      showMask: false
    };
  },
  created () {
    this.address = localStorage.getItem('address');
    this.getData();
    setInterval(this.timeOut(), 30000);
  },
  mounted () {
    this.getData();
    this.getInfo();

  },
  methods: {
    timeOut () {
      getAlarmInfo().then(res => {
        if (res.code == 1) {
          this.tzList = res.data.map(item => {
            return {
              title: item.alarmPosition,
              time: item.alarmTime
            };
          });
        }
      });
    },
    // array需要拆分的数组
    splitArray (array, size) {
      let data = [];
      for (let i = 0; i < array.length; i += size) {
        data.push(array.slice(i, i + size));
      }
      return data;
    },
    godetail(item){
      if(item.isCheck===true){
        this.$router.push(`${ item.navigateTo }`);
      }else{
        return;
      }
    },
    jumpshouye () {
      const ID = JSON.parse(sessionStorage.getItem('INFO'));

      sessionStorage.setItem('ID', ID.id);
      sessionStorage.setItem('tenantId', ID.tenantId);
      localStorage.setItem('address', ID.tenantName);

      const mapPoint = {
        longitude: ID.longitude,
        latitude: ID.latitude,
        name: ID.tenantName
      };
      localStorage.setItem('key', JSON.stringify(mapPoint));

      this.$router.push({
        path: '/overview'
      });
    },

    toback () {
      document.documentElement.requestFullscreen();
    },
    closeMask (item) {
      this.showMask = item;
    },
    //获取弹窗数据
    async getTabal (e, type) {




      this.NavList = e;
      if (e.status == 'yearNum') {
        this.modelTitle = `作业概况---${ e.type }作业`;
      } else {
        this.modelTitle = `${ type }---${ e.type }订单`;
      }
      if (e.status == 'todayStart') {
        if (e.type == '育秧') {
          this.TableTitle = this.TableTitle1;
        } else if (e.type == '烘干') {
          this.TableTitle = this.TableTitle6;
        } else if (e.type == '加工') {
          this.TableTitle = this.TableTitle4;
        } else if (e.type == '植保') {
          this.TableTitle = this.TableTitle3;
        } else if (e.type == '机收') {
          this.TableTitle = this.TableTitle5;
        } else if (e.type == '机插') {
          this.TableTitle = this.TableTitle2;
        } else {
          this.TableTitle = this.TableTitle7;
        }
      } else if (e.type == '育秧') {
        this.TableTitle = this.TableTitle1A;
      } else if (e.type == '烘干') {
        this.TableTitle = this.TableTitle6A;
      } else if (e.type == '加工') {
        this.TableTitle = this.TableTitle4A;
      } else if (e.type == '植保') {
        this.TableTitle = this.TableTitle3A;
      } else if (e.type == '机收') {
        this.TableTitle = this.TableTitle5A;
      } else if (e.type == '机插') {
        this.TableTitle = this.TableTitle2A;
      } else {
        this.TableTitle = this.TableTitle7A;
      }
      let zt = false;
      this.pagData.pageNum = e;

      await getOrderInfo(e).then(res => {
        if (res.code == 1) {
          this.tableData = res.data.records.map((item, index) => {
            return Object.assign(item, {
              serialNum: (e.pageNum - 1) * e.pageSize + index + 1,
              appoint: item.appointmentStart
                ? `${ item.appointmentStart.slice(0, 10) }${
                  item.startTime
                }~${ item.appointmentEnd.slice(0, 10) }${ item.endTime }`.replace(
                  /-/g,
                  '/'
                )
                : '',
              farmer: item.farmerName
                ? `${ item.farmerName }${
                  item.jobFieldName ? `-${ item.jobFieldName }` : ''
                }`
                : '',
              jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
              jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
              seedingPrice: item.seedingPrice
                ? `${ item.seedingPrice }元/盘`
                : '',
              seedingNum: item.seedingNum ? `${ item.seedingNum }盘` : '',
              orderFinishTime: item.orderFinishTime
                ? `${ item.orderFinishTime.slice(0, 10) }`
                : '',
              zyTime: item.orderFinishTime
                ? `${ item.orderTime.slice(0, 10) }~${ item.orderFinishTime.slice(
                  0,
                  10
                ) }`.replace(/-/g, '/')
                : '',
              dryingCount: item.dryingCount ? `${ item.dryingCount }吨` : '',
              outTower: item.outTower ? `${ item.outTower }吨` : ''
            });
          });
          zt = true;
        } else {
          zt = false;
        }
      });
      this.showMask = true;



    },
    async getInfo(){

      const IDd = JSON.parse(sessionStorage.getItem('INFO'));
      console.log(IDd.id);
      await getindexData(IDd.id).then(res => {

        this.nsfwIndex=res.data;
      });
    },
    navigateTo (path) {
      // 根据点击的img来跳转到不同的路径
      this.$router.push(path);
    },
    //获取下拉框数据
    async getListData (e, type) {
      if (type == 1) {
        //获取所有土壤监测点名称
        await getSqxq(e.value).then(res => {
          if (res.code == 1) {
            this.rightData.sqxqData = res.data;
            // this.$set(this.rightData.sqxqData,'deviceId',e.value)
          }
        });
      } else if (type == 3) {
        //根据监测点名称获取虫情监测信息
        await getPestMonitoringByName(e.deviceId).then(res => {
          if (res.code == 1) {
            this.mqData2 = this.splitArray(res.data, 2);
          }
        });
      } else if (type == 4) {
        this.downQxValue = e.value;
        //获取气象信息
        await getQxdp(e.value).then(res => {
          if (res.code == 1) {
            this.rightData.qxData = res.data;
          }
        });
      } else {
        await getSeedlingsByName(e.label).then(res => {
          if (res.code == 1) {
            this.mqData = this.splitArray(res.data, 2);
          }
        });
      }
    },
    //获取接口数据
    async getData () {
      // 获取所有设备
      await device().then(res => {
        if (res.code == 1) {
          this.divList = res.data.filter(item => {
            if (item.latitude && item.longitude) {
              return item;
            }
          });
        }
      });
      // 获取所有土壤监测点名称
      await getIdName({ category: 2 }).then(res => {
        if (res.code == 1) {
          this.downList1 = res.data.map(item => {
            return {
              value: item.deviceId,
              label: item.deviceName
            };
          });
        }
      });

      await getSeedlingNames().then(res => {
        if (res.code == 1) {
          this.downList2 = res.data.map(item => {
            return {
              label: item,
              value: item
            };
          });
        }
      });
      await getNtmj().then(res => {
        if (res.code == 1) {
        }
      });
      //获取农田基本情况
      await getNtjbqk().then(res => {
        if (res.code == 1) {
          this.ntjbqk = res.data;
        }
      });
      let dw = '';
      //获取气象站名称和设备id
      await getIdName({ category: 1 }).then(res => {
        if (res.code == 1 && res.data.length) {
          this.downList4 = res.data.map(item => {
            return {
              value: item.deviceId,
              label: item.deviceName
            };
          });
          this.downQxValue = res.data[0].deviceId;
        }
      });
      //获取气象信息
      await getQxdp(this.downQxValue).then(res => {
        if (res.code == 1) {
          this.rightData.qxData = res.data;
        }
      });
      //获取墒情信息
      if (this.downList1.length) {
        await getSqxq(this.downList1[0].value).then(res => {
          if (res.code == 1) {
            this.rightData.sqxqData = res.data;
          }
        });
      }
      // 获取水质信息
      await getWaterQuality().then(res => {
        if (res.code == 1) {
          this.rightData.szData = res.data;
        }
      });
      //获取农田基本情况
      // await getNtjbqk().then(res => {
      //   if (res.code == 1) {
      //     this.ntjbqk = res.data;
      //   }
      // });
      // 获取农田基本情况
      await fieldBasic().then(res => {
        if (res.code == 1) {
          // this.ntjbqk = res.data[0];
          this.ntjbqk = {
            totalArea: res.data[0].totalArea,
            ownFieldArea: res.data[0].ownArea,
            grainFieldArea: res.data[0].functionalArea,
            agricultureCount: res.data[0].agricultureEntitiesNum,
            typeList: res.data[0].plantingType
          };
        }
      });
      await selectAgriType().then(res => {
        if (res.code == 1) {
          this.option.series[0].data = res.data.map(item => {
            return {
              value: item.COUNT,
              name: item.car_type
            };
          });
        }
      });
      await selectAgriMain().then(res => {
        if (res.code == 1) {
          this.$set(this.option, 'operatorCount', res.data.operatorCount);
        }
      });

      await getAllprice().then(res => {
        if (res.code == 1) {
          this.listData1[2] = res.data;
          this.$forceUpdate();
        }
      });

      await getIndexNJcount({ type: 1, carType: '', unit: '台' }).then(res => {
        if (res.code == 1) {
          this.listData1[3] = res.data;
          this.$forceUpdate();
        }
      });

      let list = [ 'todayStart', 'todayFinish', 'todayNum', 'yearNum' ];
      await list.forEach((item, index) => {
        getMain({
          status: item
        }).then(res => {
          if (res.code == 1) {
            this.listData[index] = res.data;

            this.$forceUpdate();
          }
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}
.rightBgc {
  width: companyW(90vw);
  height: companyH(30vh);
  background-color: #4a515a;
  border-radius: companyW(5vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 companyW(10vw) 0 companyW(10vw);
  .imgBox {
    width: companyW(24vw);
    height: companyH(24vh);
  }
  p {
    color: #fff;
    font-size: companyH(18vh);
  }
}
.anniu1 {
  position: absolute;
  left: companyW(30vw);
  top: companyH(8vh);
}
.anniu2 {
  position: absolute;
  left: companyW(134vw);
  top: companyH(8vh);
}
.anniu3 {
  position: absolute;
  right: companyW(30vw);
  top: companyH(8vh);
}
.home {
  overflow: hidden;
  width: companyW(1920vw);
  height: companyH(1080vh);
  background-color: #1d2531;

  .homebj {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;

    /* 垂直居中 */
    .toubu {
      width: 100%;
      height: companyH(57vh);
      font-size: 40px;
      text-align: center;
      padding-top: companyH(14vh);
      font-family: DingTalk JinBuTi;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
      background-image: url('../assets/home-page/header.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: fixed;
      top: 0;
    }

    .homeBox {
      width: 100%;
      height: companyH(910vh);
      padding: companyH(70vh) companyW(20vw) companyH(20vh) companyW(20vw);
      display: flex;
      justify-content: space-between;

      .homeBoxLeft {
        width: companyW(450vw);
        height: companyH(950vh);

        .leftCenter,
        .leftTop,
        .leftBottom {
          width: 100%;
          height: 32%;
          margin-bottom: companyH(20vh);
        }
      }

      .homeBoxCenter {
        width: companyW(950vw);
        height: companyH(970vh);
      }

      .homeBoxRight {
        width: companyW(450vw);
        height: companyH(950vh);

        .rightTop,
        .rightCenter,
        .rightBotton {
          width: 100%;
          height: 32%;
          margin-bottom: companyH(10vh);
        }
      }
    }
  }

  .nsfw {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('../assets/home-page/bj.png');
    background-size: 200% 100%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0px 15px 10px -15px #000;

    .shadow {
      height: companyH(80vh);
      width: 100%;
      position: absolute;
      background: linear-gradient(RGBA(12, 15, 20, 1), 60%, transparent);
    }

    .nsfwlogo {
      height: 90%;
      width: 100%;
      top: companyH(120vh);
      position: absolute;
      background-image: url('../assets/home-page/nsfu.png');
      background-size: 150% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .nsfw-box {
      position: absolute;
      width: 100%;
      height: companyH(400vh);
      top: companyH(480vh);

      .nsfw-item {
        text-align: center;
        position: absolute;
        color: white;
        height: companyH(100vh);
        width: companyW(80vw);
        img {
          width: 100%;
          height: 80%;
        }
      }

      .isUser {
        text-align: center;
        position: absolute;
        color: white;
        height: companyH(100vh);
        width: companyW(80vw);
        transition: all 0.6s; //设置动画执行的时间为0.6s
        cursor: pointer;
      }
      .isUser:hover {
        transform: scale(1.3); //设置图片按照比例放大1.3倍
      }


      .one {
        top: 0%;
        left: 55%;
      }

      .two {
        top: 0%;
        right: 55%;
      }

      .three {
        top: 12%;
        left: 70%;
      }

      .four {
        top: 12%;
        right: 70%;
      }

      .five {
        top: 30%;
        left:82%;
        height: companyH(110vh);
        width: companyW(90vw);
      }

      .six {
        top: 30%;
        right:82%;
        height: companyH(110vh);
        width: companyW(90vw);
      }

      .seven {
        bottom: 15%;
        left: 75%;
        height: companyH(115vh);
        width: companyW(95vw);
      }

      .eight {
        bottom: 15%;
        right: 75%;
        height: companyH(115vh);
        width: companyW(95vw);
      }

      .nine {
        bottom: 6%;
        left: 60%;
        height: companyH(120vh);
        width: companyW(100vw);
      }

      .ten {
        bottom: 6%;
        right: 60%;
        height: companyH(120vh);
        width: companyW(100vw);
      }
      .eleven {
        bottom: 0;
        left: 45%;
        height: companyH(120vh);
        width: companyW(100vw);
      }
    }

    .sznj {
      // width: companyW(117vw);
      // height: companyH(149vh);
    }

    .sznt {
      // width: companyW(105vw);
      // height: companyH(135vh);
      position: fixed;
      left: companyW(561vw);
      bottom: companyH(280vh);
    }

    .yyzx {
      // width: companyW(105vw);
      // height: companyH(135vh);
      position: fixed;
      right: companyW(561vw);
      bottom: companyH(280vh);
    }

    .hgzx {
      // width: companyW(93vw);
      // height: companyH(118vh);
      position: fixed;
      left: companyW(348vw);
      bottom: companyH(390vh);
    }

    .jgzx {
      // width: companyW(93vw);
      // height: companyH(118vh);
      position: fixed;
      right: companyW(355vw);
      bottom: companyH(390vh);
    }

    .njfw {
      // width: companyW(75vw);
      // height: companyH(94vh);
      position: fixed;
      left: companyW(511vw);
      bottom: companyH(540vh);
    }

    .gxtz {
      // width: companyW(75vw);
      // height: companyH(94vh);
      position: fixed;
      right: companyW(511vw);
      bottom: companyH(540vh);
    }
  }

  .nsfw > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nsfw > div > span {
    width: 80px;
    height: 19px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #e9fbff;
    line-height: 55px;
  }
}
.dyr-service-right {
  margin-right: companyW(20vw);
  height: companyH(338vh);
}
.noCheck{
  pointer-events: none;
}
</style>
