<template>
  <div class="dry-power">

    <div class="title">基本信息</div>
    <div class="dry-power-content">
      <!-- <div>
        <img :src= xinxiData.img  class="ingBox" />
      </div> -->
      <div class="xinxi">
        <img :src="xinxiData.img" class="imgBox" />
        <div class="pBox">
          <p>
            {{ xinxiData.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBasicInfo } from '@api/njfw/njfw.js';
export default {
  components: {

  },
  created () {
    this.getList();
  },
  data () {
    return {
      xinxiData: ''
    };
  },
  methods: {
    getList () {
      getBasicInfo({})
        .then(res => {
          this.xinxiData = res.data[0];
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  width: 100%;
  height: 100%;
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(
      0deg,
      rgba(130, 245, 255, 0.4),
      rgba(130, 245, 255, 0.1)
    )
    10 10;

  .title {
    width: calc(100% - companyW(50vw));
    padding-left: companyW(50vw);
    height: companyH(40vh);
    line-height: companyH(40vh);
    color: #ffffff;
    font-weight: bold;
    background-image: url('../../../assets/home-page/titleImg.png');
    background-size: 100% 200%;
    background-repeat: no-repeat;
  }

  .dry-power-content {
    margin: 0 auto;

    width: companyW(422vw);
    .xinxi {
      max-height: companyH(260vh);
      overflow: auto;
    }
    .imgBox {
      width: companyW(202vw);
      height: companyH(127vh);
      margin-right: 10px;
      text-align: justify; /* 两端对齐 */
      float: left;
    }

    p {
      font-size: companyW(18vw);
      color: #fff;
      line-height: companyH(34vh);
      text-align: justify; /* 两端对齐 */
    }
  }
}
.xinxi::-webkit-scrollbar {
  width: 5px; /* 设置滚动条的宽度 */
  // height: 10px; /* 设置滚动条的高度 */
  // background-color: #F5F5F5; /* 设置滚动条的背景色 */
  // border-radius: 5px; /* 设置滚动条的圆角 */
}
</style>
