import request from '@/utils/request.js';
// import axios from 'axios';
// 获取实时农废量
export function selectAll (params) {
  return request({
    url: '/agriWasteOrders/selectAll',
    method: 'get',
    params
  });
}

// 获取农废回收量饼状图数据
export function getPieData (params) {
  return request({
    url: '/agriWasteOrders/getPieData',
    method: 'get',
    params
  });
}

// 获取每日回收量曲线图数据
export function getLineData (params) {
  return request({
    url: '/agriWasteOrders/getLineData',
    method: 'get',
    params
  });
}



// 获取每日回收量曲线图数据
export function getindexData (params) {
  return request({
    url: `/model/get/${ params }`,
    method: 'get',
  });
}
