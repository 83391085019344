<template>
  <div :class="NavList.status | status">
    <el-dialog :visible.sync="chaMask" :show-close="false" @close="closeMask">
      <dv-border-box-1>
        <div class="modelTltle">{{ modelTitle }}</div>
        <div class="close" @click="closeMask"></div>
        <div class="ToolBox">
          <slot></slot>
          <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="TanleContent"
            style="width: 100%"
          >
            <template v-for="v in mainTable">
              <el-table-column
                v-if="v.png"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row[v.prop]"
                  ></el-image>
                </template>
              </el-table-column>

              <!-- 作业轨迹按钮 -->
              <el-table-column
                v-else-if="v.btn"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <div class="toolStyle" @click="open(scope.row)">查看路径</div>
                </template>
              </el-table-column>
              <!-- 操作 operate -->
              <el-table-column
                v-else-if="v.operate"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <div class="fpdd">分配订单</div>
                </template>
              </el-table-column>
              <!-- 育苗品种 -->
              <el-table-column
                v-else-if="v.seedingVariety"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <!-- <div class="fpdd">分配订单</div> -->
                  <div style="text-align: left">
                    <div class="ya" v-if="scope.row.seedingType == '芽苗'">
                      芽
                    </div>
                    <div
                      class="yang"
                      v-else-if="scope.row.seedingType == '秧苗'"
                    >
                      秧
                    </div>
                    <span>{{ scope.row.seedingVariety }}</span>
                  </div>
                </template>
              </el-table-column>
              <!-- 交苗地址 -->
              <el-table-column
                v-else-if="v.address"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <!-- <div class="fpdd">分配订单</div> -->
                  <span v-if="scope.row.deliveryType == '自取'">中心自取</span>
                  <span
                    v-else-if="scope.row.deliveryType == '配送'"
                    class="ckdz"
                    >查看地址</span
                  >
                </template>
              </el-table-column>
              <!-- 评价星星 -->

              <el-table-column
                v-else-if="v.pj"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              >
                <template slot-scope="scope">
                  <el-rate
                    v-model="scope.row[v.prop]"
                    :disabled="true"
                    :colors="NavList.status | starColor"
                    disabled-void-color="#77787b"
                  ></el-rate>
                </template>
              </el-table-column>
              <!-- 必要的主体 -->
              <el-table-column
                v-else
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
              ></el-table-column>
            </template>
          </el-table>
        </div>
        <div class="sz-pagination">
          <el-pagination
            :current-page.sync="currentPage"
            @current-change="currentChange"
            :total="tableTotal"
            layout=" slot,prev, pager, next"
          >
            <span class="fy-slot">共{{ tableTotal }}条记录</span>
          </el-pagination>
        </div>
      </dv-border-box-1>
    </el-dialog>

    <mapModel
      :mspMask="mspMask"
      v-on="$listeners"
      @closeMapMask="closeMapMask"
      :mapInfo="mapInfo"
    >
    </mapModel>
  </div>
</template>

<script>
import MidChart from '@/views/njall/components/njzl/ToolChart.vue';
import mapModel from '../../njall/components/njdd/mapMode.vue';

// import * as options from "@/views/njall/mock/njToolMock.js";
export default {
  components: {
    MidChart,
    mapModel
  },

  props: {
    tableTotal: {
      type: Number,
      default: 0
    },
    changeMask: {
      type: Boolean,
      default: false
    },
    mainTable: {
      type: Array,
      default: () => []
    },
    NavList: {
      type: Object,
      default: () => {}
    },
    TanleContent: {
      type: Array,
      default: () => []
    },
    modelTitle: {
      type: String,
      default: ''
    }
  },
  filters: {
    //
    status (value) {
      switch (value) {
      case 'todayStart':
        return 'xz';
      case 'todayFinish':
        return 'wc';
      case 'todayNum':
        return 'gzl';
      case 'yearNum':
        return 'wc';
      }
    },
    starColor (value) {
      switch (value) {
      case 'todayStart':
        return [ '#00A8FF', '#00A8FF', '#00A8FF' ];
      case 'todayFinish':
        return [ '#01A77C', '#01A77C', '#01A77C' ];
      case 'todayNum':
        return [ '#A9BBD9', '#A9BBD9', '#A9BBD9' ];
      case 'yearNum':
        return [ '#01A77C', '#01A77C', '#01A77C' ];
      }
    }
  },
  data () {
    return {
      mapInfo: {},
      mspMask: false,

      currentPage: 1,
      tabLoading: true,
      currentInd: 1,
      // ...options,
      chaMask: false,
      rateColor: [ '#009ad6' ]
    };
  },
  watch: {
    changeMask: {
      handler (newv, oldv) {
        this.chaMask = newv;
      }
    }
  },
  methods: {
    open (row) {
      console.log(row);
      this.mapInfo = row;
      this.mspMask = true;
    },
    closeMapMask () {
      this.mspMask = false;
      this.mapInfo = null;
    },
    //弹窗改变
    currentChange (e) {
      this.tabLoading = true;

      this.$listeners.getTabal(
        Object.assign(this.NavList, {
          pageNum: e
        })
      );
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    closeMask () {
      this.currentPage = 1;
      this.chaMask = false;
      this.$emit('getMask', false);
    },
    changeNav (id) {
      this.tabLoading = true;
      this.currentInd = id;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    }
  },

  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
    console.log(this.chaMask);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}

@mixin ys($color1, $color2, $color3, $color4) {
  ::v-deep .el-dialog {
    width: companyW(1499vw);
    background: #000000;
    opacity: 0.9;
  }
  ::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  .modelTltle {
    width: 100%;
    height: companyH(70vh);
    font-size: companyW(40vw);
    color: $color1;
    line-height: companyH(70vh);
    text-align: center;
  }
  .close {
    width: companyW(37vw);
    height: companyH(37vh);
    background: url('../../../assets/close.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .ckdz {
    color: $color4;
    text-decoration: underline;
  }
  .ToolBox {
    width: 95%;
    // height: companyH(605vh);
    margin: 0 auto;
    padding: 0 0 companyH(10vh);
    display: flex;
  }

  ::v-deep .el-table th {
    background: $color2;
    border-top: companyW(1vw) solid $color1;
    border-bottom: companyW(1vw) solid $color1;
    height: companyH(35vh);
    padding: 0;
    text-align: center;
  }
  ::v-deep .cell {
    font-size: companyH(14vh);
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #fff;
  }
  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: rgba(255, 255, 255, 0);
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    color: $color4;
  }
  ::v-deep .el-table td,
  .building-top .el-table th.is-leaf {
    border-bottom: 1px solid rgba(238, 238, 238, 0);
  }
  ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #71cef916;
    // opacity: 0.1;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-table tr,
  ::v-deep .el-table::before,
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #000000;
  }
  .toolStyle {
    color: $color4 !important;
    font-size: companyH(14vh);
    text-decoration: underline;
    cursor: pointer;
  }

  .sz-pagination {
    margin: companyH(10vh) 0;
    height: companyH(80vh);
    text-align: center;
    width: 100%;
    .fy-slot {
      margin-right: companyW(10vw);
      color: $color1;
    }
    ::v-deep {
      .btn-prev {
        background: #000;
      }
      .el-pager {
        background: #000;
        color: $color1;

        .number {
          background: #000;
          border: 1px solid $color1;
        }
        .number:hover {
          color: #fff;
        }
      }
      .btn-next {
        background-color: black;
      }
      .el-icon-arrow-right {
        background-color: black;
      }
      .el-icon-arrow-left:before {
        color: $color1;
        border: 1px solid $color1;
      }
      .el-icon-arrow-right:before {
        color: $color1;
        border: 1px solid $color1;
      }
      .active {
        color: #fff;
        font-weight: 400;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        background-color: #000;
        color: $color1;
        border: 1px solid $color1;
      }
    }
  }
  .fpdd {
    width: companyW(100vw);
    height: companyH(35vh);
    line-height: companyH(35vh);
    font-size: companyW(14vw);
    background-color: #243948;
    color: $color4;
    border: 1px solid $color4;
    margin: 0 auto;
  }
}
// $red:#f00;
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.xz {
  $color1: #1c65b8;
  $color2: #015784;
  $color3: #71cdf9;
  $color4: #00a8ff;
  @include ys($color1, $color2, $color3, $color4);
}
.wc {
  $color1: #07b185;
  $color2: #013f2e;
  $color3: #01a77c;
  $color4: #2affc8;
  @include ys($color1, $color2, $color3, $color4);
}
.gzl {
  $color1: #a9bbd9;
  $color2: #1e2d45;
  $color3: #a9bbd9;
  $color4: #a9bbd9;
  @include ys($color1, $color2, $color3, $color4);
}
.ya {
  text-align: center;
  margin-right: companyW(7vw);
  display: inline-block;
  width: companyW(35vw);
  height: companyH(30vh);
  border-radius: 5px;
  font-weight: bold;
  background-color: #4e6415;
}
.yang {
  text-align: center;
  margin-right: companyW(7vw);
  display: inline-block;
  width: companyW(35vw);
  height: companyH(30vh);
  border-radius: 5px;
  font-weight: bold;
  background-color: #1b826f;
}
</style>
