<template>
    <div id="drag-container">
      <div id="spin-container">
        <div class="img-con">
          <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
        <div class="img-con">
            <img ref="img" src="../../assets/home-page/jgzx.png" alt="" />
        </div>
      </div>
      <div id="ground"></div>
    </div>
  </template>
   
  <script>
  export default {
    name: "",
    props: {},
    components: {},
    data() {
      return {
        radius: 440,
        autoRotate: true,
        rotateSpeed: -60,
        imgWidth: 220,
        imgHeight: 150,
        sX: 0,
        sY: 0,
        nX: 0,
        nY: 0,
        desX: 0,
        desY: 0,
        tX: 0,
        tY: 10,
        obox: null,
        ospin: null,
        aImg: null,
        aVid: null,
        aEle: null,
      };
    },
    created() {
      this.$nextTick(() => {
        let _this = this;
   
        this.obox = document.getElementById("drag-container");
        this.ospin = document.getElementById("spin-container");
        this.aImg = this.ospin.getElementsByClassName("img-con");
   
        this.aVid = this.ospin.getElementsByTagName("video");
       
        this.aEle = [...this.aImg, ...this.aVid];
        setTimeout(this.init(), 100);
        this.ospin.style.width = this.imgWidth + "px";
        this.ospin.style.height = this.imgHeight + "px";
   
        var ground = document.getElementById("ground");
        ground.style.width = this.radius * 3 + "px";
        ground.style.height = this.radius * 3 + "px";
   
        if (this.autoRotate) {
          var animationName = this.rotateSpeed > 0 ? "spin" : "spinRevert";
          this.ospin.style.animation = `${animationName} ${Math.abs(
            this.rotateSpeed
          )}s infinite linear`;
        }
   
        if (this.mobilecheck()) {
          document.ontouchstart = function (e) {
            clearInterval(_this.obox.timer);
            e = e || window.event;
            var sX = e.touches[0].clientX;
            var sY = e.touches[0].clientY;
   
            this.ontouchmove = function (e) {
              e = e || window.event;
              var nX = e.touches[0].clientX;
              var nY = e.touches[0].clientY;
              _this.desX = nX - sX;
              _this.desY = nY - sY;
              _this.tX += _this.desX * 0.1;
              _this.tY += _this.desY * 0.1;
              _this.applyTranform(_this.obox);
              sX = nX;
              sY = nY;
            };
   
            this.ontouchend = function (e) {
              this.ontouchmove = this.ontouchend = null;
              _this.obox.timer = setInterval(function () {
                _this.desX *= 0.95;
                _this.desY *= 0.95;
                _this.tX += _thisdesX * 0.1;
                _this.tY += _thisdesY * 0.1;
                _this.applyTranform(_this.obox);
                _this.playSpin(false);
                if (Math.abs(_this.desX) < 0.5 && Math.abs(_this.desY) < 0.5) {
                  clearInterval(_this.obox.timer);
                  _this.playSpin(true);
                }
              }, 17);
            };
          };
        } else {
          document.onmousedown = function (e) {
            clearInterval(_this.obox.timer);
            e = e || window.event;
            var sX = e.clientX,
              sY = e.clientY;
   
            this.onmousemove = function (e) {
              e = e || window.event;
              var nX = e.clientX,
                nY = e.clientY;
              _this.desX = nX - sX;
              _this.desY = nY - sY;
              _this.tX += _this.desX * 0.1;
              _this.tY += _this.desY * 0.1;
              _this.applyTranform(_this.obox);
              sX = nX;
              sY = nY;
            };
   
            this.onmouseup = function (e) {
              this.onmousemove = this.onmouseup = null;
              _this.obox.timer = setInterval(function () {
                _this.desX *= 0.95;
                _this.desY *= 0.95;
                _this.tX += _this.desX * 0.1;
                _this.tY += _this.desY * 0.1;
                _this.applyTranform(_this.obox);
                _this.playSpin(false);
                if (Math.abs(_this.desX) < 0.5 && Math.abs(_this.desY) < 0.5) {
                  clearInterval(_this.obox.timer);
                  _this.playSpin(true);
                }
              }, 13);
            };
   
            return false;
          };
          document.onmousewheel = function (e) {
            e = e || window.event;
            var d = e.wheelDelta / 20 || -e.detail;
            this.radius = this.radius + d;
            this.init(1);
          };
        }
      });
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
      init(delayTime) {
        for (var i = 0; i < this.aEle.length; i++) {
          this.aEle[i].style.transform =
            "rotateY(" +
            i * (360 / this.aEle.length) +
            "deg) translateZ(" +
            this.radius +
            "px)";
          this.aEle[i].style.transition = "transform 1s";
          this.aEle[i].style.transitionDelay =
            delayTime || (this.aEle.length - i) / 4 + "s";
        }
      },
      applyTranform(obj) {
       console.log(this.tX,'this.tX');
        if (this.tY > 180) this.tY = 180;
        if (this.tY < 0) this.tY = 0;
        obj.style.transform =
          //2D效果  只可以左右滑动，不可以上下滑动  -7.2的值和css中一致
          "rotateX(" + -7.2 + "deg) rotateY(" + this.tX + "deg)";
          // "rotateX(" + -this.tY + "deg) rotateY(" + this.tX + "deg)";
      },
      playSpin(yes) {
        this.ospin.style.animationPlayState = yes ? "running" : "paused";
      },
      mobilecheck() {
        var check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
    },
  };
  </script>
  <style lang='scss' scoped>
  #app {
    width: 100%;
    overflow: hidden;
    display: flex;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
  * {
    margin: 0;
    padding: 0;
  }
   
  html,
  body {
    height: 100%;
  }
   
  body {
    overflow: hidden;
    display: flex;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
   
  #drag-container,
  #spin-container {
    position: relative;
    display: flex;
    margin: auto;
    transform-style: preserve-3d;
    transform: rotateX(-7.2deg);//初始偏移值
  }
   
  #drag-container img,
  #drag-container .img-con,
  #drag-container video {
    transform-style: preserve-3d;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 200px;
    font-size: 50px;
    text-align: center;
    box-shadow: 0 0 8px #fff;
    -webkit-box-reflect: below 10px
      linear-gradient(transparent, transparent, #0005);
  }
   
  #drag-container img:hover,
  #drag-container .img-con:hover,
  #drag-container video:hover {
    box-shadow: 0 0 15px #fffd;
    -webkit-box-reflect: below 10px
      linear-gradient(transparent, transparent, #0007);
  }
   
  #drag-container p {
    font-family: Serif;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotateX(90deg);
    color: #fff;
  }
   
  #ground {
    width: 900px;
    height: 900px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotateX(90deg);
    background: -webkit-radial-gradient(
      center center,
      farthest-side,
      #9993,
      transparent
    );
  }
   
  #music-container {
    position: absolute;
    top: 0;
    left: 0;
  }
   
  #carousel-container {
    width: 100%;
    height: 100%;
  }
   
  @keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  @keyframes spinRevert {
    from {
      transform: rotateY(360deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
  </style>