import request from '@/utils/request.js';
// import axios from 'axios';
export function getIndexDetail(params) {
  return request({
    url:'/agricultual/selectAgriMain',
    method:'get',
    params
  });
}

export function getIndexType(params) {
  return request({
    url:'/agricultual/selectAgriType',
    method:'get',
    params
  });
}

export function getIndexBt(params) {
  return request({
    url:'/agricultual/selectAgriAllowance' ,
    method:'get',
    params
  });
}


export function getIndexNJcount(params) {
  return request({
    url:'/agricultual/selectAgriMainByType' ,
    method:'get',
    params
  });
}


export function getIndexNJState(params) {
  return request({
    url:'/agricultual/selectAgriStatus' ,
    method:'get',
    params
  });
}

export function getIndexBFxz(params) {
  return request({
    url:'/agricultual/selectAgriStatusThreeYear' ,
    method:'get',
    params
  });
}


export function getIndexNjs(params) {
  return request({
    url:'/agriOperator/get' ,
    method:'get',
    params
  });
}

export function getDDdd(params) {
  return request({
    url:'/jobOrder/getJobOrderInfo' ,
    method:'get',
    params
  });
}


export function getWait(params) {
  return request({
    url:'/jobOrder/getWaitOrder' ,
    method:'get',
    params
  });
}

export function getOrderIn(params) {
  return request({
    url:`/jobOrder/get/${ params }`,
    method:'get',
    params
  });
}

// 获取已调度或已完成订单/未完成订单
export function getNOFin(params) {
  return request({
    url:'/jobOrder/getDispatchOrFinishOrder',
    method:'get',
    params
  });

}


export function getFreeNj(params) {
  return request({
    url:'/jobOrder/getFreeAgri',
    method:'get',
    params
  });

}


export function getFreeNjs(params) {
  return request({
    url:'/jobOrder/getFreeOperator',
    method:'get',
    params
  });

}
export function postJobOrder (data) {
  return request({
    url:'/jobOrder/allocation',
    method:'post',
    data
  });
}


export function getdevice (params) {
  return request({
    url:'/agmGps/getGPS',
    method:'get',
    params
  });
}


export function getJobOrder (params) {
  return request({
    url:'/jobOrder/get',
    method:'get',
    params
  });
}




export function getorderByAgr (params) {
  return request({
    url:'/dryingOrProcessOrder/orderByAgr',
    method:'get',
    params
  });
}
