<template>
  <div>
    <div id="mapDiv"></div>
  </div>
</template>

<script>
import { getMACHINE } from '@/api/njApi/njdd.js';

export default {
  props: {
    mapInfo: {
      type: Object
    }
  },
  created () {
  },

  mounted () {
  },
  watch: {
    mapInfo: {

      immediate:true,
      async handler (newValue) {
        if(newValue.outId===null){
          this.$message({
            message: '该农机暂无北斗，请加装北斗后查看',
            type: 'warning'
          });

        }else if(newValue===null){
          return;

        }else{
          await this.getInfo();
          await this.roadMap();
        }

      }
    }
  },
  data () {
    return {
      map: null,
      pathCoordinates: []
    };
  },
  methods: {
    roadMap () {
      // 地图设置

      const tile = new T.TileLayer(
        'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=c9cd7951db1ea5b311aae41f5c0d60e4'
      );

      this.map = new T.Map('mapDiv', { layers: [ tile ] });
      this.map.centerAndZoom(
        new T.LngLat(this.pathCoordinates[0].lng, this.pathCoordinates[0].lat),
        16
      );
      console.log(this.map,'this.map');
      // 线的一些基本配置
      const lineConfig = {
        color: 'yellow', // 线的颜色
        weight: 2, // 线的宽度
        opacity: 1, // 线的透明度
        lineStyle: 'dashed' // 线的样式
      };

      // 创建点对象集合并创建几个点放进集合中

      var points = [];

      for (var i = 0; i < this.pathCoordinates.length; i = i + 1) {
        points.push(
          new T.LngLat(this.pathCoordinates[i].lng, this.pathCoordinates[i].lat)
        );
      }

      const line = new T.Polyline(points, lineConfig); // 创建线条的对象

      // 向地图上添加线
      this.map.addOverLay(line);
    },
    async getInfo () {
      const { data } = await getMACHINE({
        outId: this.mapInfo.outId,
        startTime: this.mapInfo.orderStartTime,
        endTime: this.mapInfo.orderFinishTime
      });

      for (let i = 0; i < data.length; i++) {
        const res = { lng: +data[i].lng, lat: +data[i].lat };
        this.pathCoordinates.push(res);
      }
    }
  }
};
</script>

<style scoped>
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: '微软雅黑';
}

#mapDiv {
  height: 100%;
  width: 100%;
}
</style>
