<template>
  <div>
    <el-dialog
      :visible.sync="mspMask"
      :show-close="false"
      @close="closeMask"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"


    >
      <dv-border-box-1>
        <div class="mapModel">
          <div class="back" @click="closeMask">返回</div>
          <div class="mapTitle">作业路径</div>
          <Tmap :mapInfo="mapInfo" class="map"></Tmap>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>
<script>
import Tmap from '../TMap/map_gj.vue';
export default {
  props: {
    mspMask: {
      type: Boolean
    },
    mapInfo: {
      type: Object
    }
  },
  data () {
    return {};
  },
  components: {
    Tmap: Tmap
  },
  methods: {
    closeMask () {
      this.$listeners.closeMapMask();

      // this.$emit('closeMapMask');
    }
  }
};
</script>
<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.mapModel {
  position: relative;
  width: 100%;
  height: companyH(700vh);
  padding: companyW(40vw);
}

.close {
  width: companyW(40vw);
  height: companyW(40vw);
  background: url('../../../../assets/close.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: companyH(25vh);
  right: companyW(35vw);
}

.back {
  height: companyH(40vh);
  width: companyW(70vw);
  background-image: url('../../../../assets/njdd/xf.png');
  text-align: center;
  line-height: companyH(40vh);
  font-size: companyW(14vw);
  color: #009bed;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: companyH(20vh);
}
.mapTitle {
  font-size: companyW(20vw);
  color: #15a1ec;
  margin-bottom: companyH(20vh);
}
.map {
  width: 95%;
  height: companyH(550vh);
}
</style>
