import request from '@/utils/request.js';
// import axios from 'axios';
export function getDetail(params) {
  return request({
    url:'/subsidy/get',
    method:'get',
    params
  });
}

export function getfarmer(params) {
  return request({
    url:'/farmer/get',
    method:'get',
    params
  });
}


export function jobOrder(data) {
  return request({
    url:'/jobOrder/save',
    method:'post',
    data
  });
}

export function getFiled(params) {
  return request({
    url:'/field/getFieldByFarmerId',
    method:'get',
    params
  });
}


export function getWorkNj(params) {
  return request({
    url:'/jobOrder/getWorkAgri',
    method:'get',
    params
  });
}


export function getJobTypePrice(params) {
  return request({
    url:'jobPrice/getAll',
    method:'get',
    params
  });
}
export function getMACHINE(params) {
  return request({
    url:'/agmGps/getLocation',
    method:'get',
    params
  });
}




// 根据作业类型查询作业对象以及价格
export function getByType(params) {
  return request({
    url:'/jobPrice/getByType',
    method:'get',
    params
  });
}


// 根据作业类型和作业对象查询具体价格
export function getDetailPrice(params) {
  return request({
    url:'/jobPrice/getDetailPrice',
    method:'get',
    params
  });
}

// 获取作业类型价格
export function getAllprice(params) {
  return request({
    url:'/jobPrice/getAll',
    method:'get',
    params
  });
}
