import request from '@/utils/request.js';
//获取农田总面积
export function getNtjbqk(params) {
  return request({
    url: '/field/getFieldBasicSituation',
    method: 'get',
    params: params,
  });
}
//驾驶舱农机总览首页获取农机类型数据

export function selectAgriType(params) {
  return request({
    url: '/agricultual/selectAgriType',
    method: 'get',
    params: params,
  });
}

//驾驶舱农机总览首页获取数据
export function selectAgriMain(params) {
  return request({
    url: '/agricultual/selectAgriMain',
    method: 'get',
    params: params,
  });
}

//获取首页数据屏数据
export function getMain(params) {
  return request({
    url: '/dryingOrProcessOrder/getMain',
    method: 'get',
    params: params,
  });
}
//获取首页数据屏订单数据 /dryingOrProcessOrder/getOrderInfo?status=todayStart&type=烘干&pageNum=&pageSize=5
export function getOrderInfo(params) {
  return request({
    url: '/dryingOrProcessOrder/getOrderInfo',
    method: 'get',
    params: params,
  });
}
